import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import vu1 from "../../images/12312321.jpg"
import vu2 from "../../images/34345534.jpg"
import vu3 from "../../images/657567675.jpg"

const voldikUksed = () => (
  <Layout>
    <SEO title="Voldikuksed" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>VOLDIKUKSED</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/ruumijagajad/voldikuksed/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/lootsuksed/">
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/siirdeseinad/">
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/voldikseinad/">
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/referentsid/">
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="voldikuksed-wrapper">
        <div className="voldikuksed-text">
          <h3>Voldikuksed</h3>
          <p>
            Liigenduks (voldikuks) lisab ruumile tavalise uksega võrreldes
            aktiivset ruumi umbes ühe ruutmeetri võrra ja sobib igasuguste
            ruumide jagamiseks. Liigenduks hoiab ruumi kokku ja kaunistab
            ruumi.Ehtsa puidupinnaga kaetud liigenduks valmistatakse alati
            mõõtude järgi.Laia puupinna ja klaasi (polüstüreen) valiku hulgast
            on võimalik leida oma ruumidesse sobivaim.Liigenduste paneelide
            ühendamisel kasutatakse terasvedru hingesid, mis tagab liigendukse
            ühtlase hoiaku igas asendis. Liigenduks riputatakse
            kuullaager-liuguritega, mis tagab ukse vaikse ja sujuva liikumise.
            Kokku lükatult võtab liigenduks ruumi ca 10% kogulaiusest +
            lukuliist
          </p>
        </div>

        <div className="voldikuksed-menu">
          <Link to="/ruumijagajad/voldikuksed/puidujaklaasivalik/">
            <div className="ruumijagajad-menu-item">
              <h2>Puidu ja klaasi valik</h2>
            </div>
          </Link>
          <Link to="/ruumijagajad/voldikuksed/topeltvoldikuksed">
            <div className="ruumijagajad-menu-item">
              <h2>Topeltvoldikuksed</h2>
            </div>
          </Link>
        </div>

        <div className="subpage-img voldikuksed-img">
          <img src={vu1} alt="Voldikuks1" width="150"></img>
          <img src={vu2} alt="Voldikuks2" width="150"></img>
          <img src={vu3} alt="Voldikuks3" width="120"></img>
        </div>
      </div>
    </div>
  </Layout>
)

export default voldikUksed
